import { createStyles, Text } from '@mantine/core';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { EdgeUrlProps, getEdgeUrl } from '~/client-utils/cf-images-utils';
import { EdgeVideo } from '~/components/EdgeMedia/EdgeVideo';
import { SyntheticEvent, useRef } from 'react';
import { env } from '~/env/client.mjs';

export type EdgeMediaProps = EdgeUrlProps &
  Omit<JSX.IntrinsicElements['img'], 'src' | 'srcSet' | 'ref' | 'width' | 'height' | 'metadata'> & {
  imageLoaded?: Function;
  controls?: boolean;
  wrapperProps?: React.ComponentPropsWithoutRef<'div'>;
  contain?: boolean;
  fadeIn?: boolean;
};

export function ModelEdgeMedia({
                                 src,
                                 alt,
                                 imageLoaded,
                                 width,
                                 fit,
                                 blur,
                                 quality,
                                 gravity,
                                 className,
                                 style,
                                 children,
                                 controls,
                                 wrapperProps,
                                 contain,
                                 fadeIn = false,
                                 ...imgProps
                               }: EdgeMediaProps) {
  const { classes, cx } = useStyles({ maxWidth: width === 'original' ? undefined : width });
  const currentUser = useCurrentUser();

  const imgRef = useRef<HTMLImageElement>(null);
  if (fadeIn && imgRef.current?.complete) imgRef?.current?.style?.setProperty('opacity', currentUser ? (currentUser?.isAdult || env.NEXT_PUBLIC_DEMO_IMAGE ? '1' : '0.2') : '1');

  if (width && typeof width === 'number') width = Math.min(width, 4096);
  let transcode = false;
  const _inferredType =
    src?.endsWith('.gif') || src?.endsWith('.mp4') || src?.endsWith('.webm') ? 'video' : 'image';

  // videos are always transcoded
  if (_inferredType === 'video') {
    transcode = true;
  }

  const optimized = currentUser?.filePreferences?.imageFormat === 'optimized';

  const _src = getEdgeUrl(env.NEXT_PUBLIC_DEMO_IMAGE ? 'lbm_demo.jpg' : src, {
    width,
    fit,
    transcode,
    blur,
    quality,
    gravity,
    optimized: optimized ? true : undefined,
    type: _inferredType,
  });

  const onLoadImg = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.style.opacity = currentUser ? (currentUser?.isAdult || env.NEXT_PUBLIC_DEMO_IMAGE ? '1' : '0.2') : '1';

    const img = new Image();

    if (!imgRef.current || !imgRef.current.src)
      return;

    img.src = imgRef.current.src;

    let rWidth: number | string = width === 'original' ? 1 : (width ?? 1);

    img.onload = () => {
      // @ts-ignore
      imageLoaded && imageLoaded(img.naturalHeight * rWidth / img.naturalWidth);
    };
  };

  switch (_inferredType) {
    case 'image':
      return (
        <img
          ref={imgRef}
          className={cx(classes.responsive, className)}
          onLoad={(e) => onLoadImg(e)}
          onError={(e) => e.currentTarget.classList.add('load-error')}
          src={_src}
          style={{ filter: currentUser ? (currentUser?.isAdult || env.NEXT_PUBLIC_DEMO_IMAGE ? '' : 'blur(40px)') : '', ...style }}
          {...imgProps}
          alt={alt}
        />
      );
    case 'video':
      return (
        <EdgeVideo
          src={_src}
          className={cx(classes.responsive, className)}
          style={{ filter: currentUser ? (currentUser?.isAdult || env.NEXT_PUBLIC_DEMO_IMAGE ? '' : 'blur(40px)') : '', ...style }}
          controls={controls}
          wrapperProps={wrapperProps}
          contain={contain}
          fadeIn={fadeIn}
        />
      );
    default:
      return <Text align="center">Unsupported media type</Text>;
  }
}

const useStyles = createStyles((theme, params: { maxWidth?: number | string }) => ({
  responsive: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    // maxWidth: params.maxWidth,
  },
}));
