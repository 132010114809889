import { Button, Center, Group, Loader, LoadingOverlay } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';
import { removeEmpty } from '~/utils/object-helpers';
import { MasonryColumns } from '~/components/MasonryColumns/MasonryColumns';
import { EndOfFeed } from '~/components/EndOfFeed/EndOfFeed';
import { IsClient } from '~/components/IsClient/IsClient';
import { InViewLoader } from '~/components/InView/InViewLoader';
import { NoContent } from '~/components/NoContent/NoContent';
import Link from 'next/link';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { ModelQueryParams, useModelFilters, useQueryModels } from '~/components/Model/model.utils';
import { ModelFilterSchema } from '~/providers/FiltersProvider';
import { AmbientModelCard } from '~/components/Model/Infinite/AmbientModelCard';

type InfiniteModelsProps = {
  filters?: Partial<Omit<ModelQueryParams, 'view'> & Omit<ModelFilterSchema, 'view'>>;
  disableStoreFilters?: boolean;
  showEof?: boolean;
  showAds?: boolean;
  showEmptyCta?: boolean;
};

export function ModelsInfinite({
  filters: filterOverrides = {},
  showEof = false,
  disableStoreFilters = false,
  showAds,
  showEmptyCta,
}: InfiniteModelsProps) {
  const features = useFeatureFlags();
  const modelFilters = useModelFilters();

  const filters = removeEmpty(
    disableStoreFilters ? filterOverrides : { ...modelFilters, ...filterOverrides }
  );
  const [debouncedFilters, cancel] = useDebouncedValue(filters, 500);

  const {
    models,
    isLoading = false,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useQueryModels(debouncedFilters, { keepPreviousData: true });

  //#region [useEffect] cancel debounced filters
  useEffect(() => {
    if (isEqual(filters, debouncedFilters)) cancel();
  }, [cancel, debouncedFilters, filters]);
  //#endregion

  return (
    <IsClient>
      {isLoading ? (
        <Center p="xl">
          <Loader />
        </Center>
      ) : (models && !!models.length) || hasNextPage ? (
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={isRefetching ?? false} zIndex={9} />

          <MasonryColumns
            data={models ?? []}
            maxItemHeight={600}
            render={AmbientModelCard}
            itemId={(data) => data.id}
            withAds={showAds}
          />

          {hasNextPage && (
            <InViewLoader
              loadFn={fetchNextPage}
              loadCondition={!isRefetching && hasNextPage}
              // Forces a re-render whenever the amount of images fetched changes. Forces load-more if available.
              style={{ gridColumn: '1/-1' }}
            >
              <Center p="xl" sx={{ height: 36 }} mt="md">
                <Loader />
              </Center>
            </InViewLoader>
          )}
          {!hasNextPage && showEof && <EndOfFeed />}
        </div>
      ) : (
        <NoContent py="lg">
          {showEmptyCta && (
            <Group>
              <Link href="/selfie_posts/create">
                <Button variant="default" radius="xl">
                  Post selfies
                </Button>
              </Link>
            </Group>
          )}
        </NoContent>
      )}
    </IsClient>
  );
}
