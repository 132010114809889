import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Indicator,
  LoadingOverlay,
  Menu,
  useMantineTheme,
} from '@mantine/core';
import { NextLink } from '@mantine/next';
import { CollectionType } from '@prisma/client';
import {
  IconAdjustmentsBolt,
  IconCategory,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconShare3,
  IconShoppingBag,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MasonryCard } from '~/components/MasonryGrid/MasonryCard';
import { AddToCollectionMenuItem } from '~/components/MenuItems/AddToCollectionMenuItem';
import { UseQueryModelReturn } from '~/components/Model/model.utils';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { openContext } from '~/providers/CustomModalsProvider';
import { ReportEntity } from '~/server/schema/report.schema';
import { trpc } from '~/utils/trpc';
import { useInView } from '~/hooks/useInView';
import { HolidayFrame } from '~/components/Decorations/HolidayFrame';
import { ReportMenuItem } from '~/components/MenuItems/ReportMenuItem';
import { ModelEdgeMedia } from '~/components/EdgeMedia/ModelEdgeMedia';
import { Reactions } from '~/components/Reaction/Reactions';
import HoverActionButton from '~/components/Cards/components/HoverActionButton';
import { Carousel, Embla } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { ShareButton } from '~/components/ShareButton/ShareButton';
import { showErrorNotification, showSuccessNotification } from '~/utils/notifications';
import { isValidUrl } from '~/utils/freq';
import { slugit } from '~/utils/string-helpers';
import { useAdjustReactionCount } from '~/components/Image/hooks/useAdjustReactionCount';
import { EdgeMedia } from '~/components/EdgeMedia/EdgeMedia';

const useStyles = createStyles((theme, _, getRef) => {
  const infoRef = getRef('info');
  const imageRef = getRef('image');

  return {
    root: {
      height: '100%',
      color: 'white',
      '&:hover': {
        [`& .${imageRef}`]: {
          transform: 'scale(1.1)',
        },
      },
    },
    link: {
      display: 'block',
    },

    content: {
      background: theme.fn.gradient({
        from: 'rgba(37,38,43,0.8)',
        to: 'rgba(37,38,43,0)',
        deg: 0,
      }),
      // backdropFilter: 'blur(13px) saturate(160%)',
      boxShadow: '0 -2px 6px 1px rgba(0,0,0,0.16)',
    },

    info: {
      ref: infoRef,
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 10,
    },
    image: {
      ref: imageRef,
      height: '100%',
      transition: 'transform 400ms ease',
      minWidth: '100%',
      width: '100%',
      zIndex: 1,
      // position: 'absolute',
      // top: '50%',
      // left: 0,
      // transform: 'translateY(-50%)',
    },
    cardBadges: {
      position: 'absolute',
      top: theme.spacing.xs,
      left: theme.spacing.xs,
      zIndex: 10,
    },

    typeBadge: {
      background: 'rgb(30 133 230 / 40%)',
    },

    floatingBadge: {
      color: 'white',
      // backdropFilter: 'blur(7px)',
      boxShadow: '1px 2px 3px -1px rgba(37,38,43,0.2)',
    },

    statusBadge: {
      background: theme.fn.rgba(theme.colors.yellow[theme.fn.primaryShade()], 0.4),
    },

    earlyAccessBadge: {
      background: theme.fn.rgba(theme.colors.green[theme.fn.primaryShade()], 0.4),
    },

    floatingAvatar: {
      position: 'absolute',
      bottom: theme.spacing.xs,
      right: theme.spacing.xs,
      zIndex: 10,
    },

    statBadge: {
      background: 'rgba(212,212,212,0.2)',
      color: 'white',
    },

    userAvatar: {
      opacity: 0.8,
      boxShadow: '0 1px 3px rgb(0 0 0 / 50%), rgb(0 0 0 / 50%) 0px 8px 15px -5px',
      transition: 'opacity .25s ease',
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: theme.radius.xl,
        boxShadow: 'inset 0 0 0px 1px rgba(255,255,255,0.8)',
      },

      '&:hover': {
        opacity: 1,
      },
    },

    hoverable: {
      opacity: 0.8,
      boxShadow: '0 1px 3px rgb(0 0 0 / 50%), rgb(0 0 0 / 50%) 0px 8px 15px -5px',
      transition: 'opacity .25s ease',
      position: 'relative',
      '&:hover': {
        opacity: 1,
      },
    },
    reactions: {
      position: 'absolute',
      bottom: 6,
      left: 6,
      borderRadius: theme.radius.sm,
      background: theme.colorScheme === 'dark' ? theme.fn.rgba('#000', 0.31) : theme.colors.gray[0],
      backdropFilter: 'blur(2px) saturate(160%)',
      boxShadow: '0 -2px 6px 1px rgba(0,0,0,0.16)',
      padding: 4,
      zIndex: 1,
    },
    icondiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '10px',
    },
    sharebtn: {
      background: 'transparent',
      border: 0,
      lineHeight: 1.15,
      fontSize: '14px',
      outline: 0,
      textAlign: 'left',
      cursor: 'pointer',
      display: 'flex',
      padding: '10px 12px',
      borderRadius: 4,
      alignItems: 'center',
      width: '100%',
      color: theme.colorScheme === 'dark' ? '#C1C2C5' : '#222',
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0],
      },
    },
    topRight: {
      position: 'absolute',
      top: theme.spacing.sm,
      right: theme.spacing.sm,
      zIndex: 10,
      display: 'grid',
      justifyItems: 'end',
    },
    imageContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // paddingBottom: 42,
      // background: theme.colors.dark[9],
      flexDirection: 'column',
      overflow: 'visible',
    },
    slide: {
      display: 'flex',
      flexDirection: 'column',
    },
    charmButton: {
      // position: 'relative',
      // background: theme.fn.rgba(theme.colors.blue[9], 0.6),
      // border: '1px solid rgba(255,255,255,0.5)',
      // '&:hover': {
      //   background: theme.fn.rgba(theme.colors.blue[6], 0.8),
      //   transform: 'none',
      //
      //   '.glow': {
      //     transform: 'scale(1.1, 1.15)',
      //   },
      // },
      //
      // '&:active': {
      //   background: theme.fn.rgba(theme.colors.blue[6], 0.8),
      //   transform: 'none',
      // },
      //
      // '.glow': {
      //   position: 'absolute',
      //   left: '0',
      //   top: '0',
      //   width: '100%',
      //   height: '100%',
      //   background: theme.fn.linearGradient(
      //     10,
      //     theme.colors.blue[9],
      //     theme.colors.blue[7],
      //     theme.colors.blue[5],
      //     theme.colors.cyan[9],
      //     theme.colors.cyan[7],
      //     theme.colors.cyan[5],
      //   ),
      //   backgroundSize: '300%',
      //   borderRadius: theme.radius.xl,
      //   filter: 'blur(4px)',
      //   zIndex: -1,
      //   animation: 'glowing 3.5s linear infinite',
      //   transform: 'scale(1.05, 1.1)',
      //   transition: 'transform 300ms linear',
      // },
    },
  };
});

const threeDayAgo = dayjs().subtract(3, 'day').toDate();

export function AmbientModelCard({ data, width: cardWidth }: Props) {
  const { ref, inView } = useInView({ rootMargin: '600px' });
  const router = useRouter();
  const modelId = router.query.model ? Number(router.query.model) : undefined;
  const { classes, cx } = useStyles();

  const { id, image_urls, name, rank, user, locked, earlyAccessDeadline } = data ?? {};

  const [loading, setLoading] = useState(false);
  const [modelCardHeight, setModelCardHeight] = useState(350);

  const [isPublished, setIsPublished] = useState(!(data?.meta && data?.meta?.unpublishedAt));

  const carouselKey = useMemo(
    () => `${data.image_urls}_${cardWidth}`,
    [data.image_urls, cardWidth]
  );
  const [embla, setEmbla] = useState<Embla | null>(null);

  const adjustReactionCount = useAdjustReactionCount();

  const currentUser = useCurrentUser();
  const isModerator = currentUser?.isModerator ?? false;

  const theme = useMantineTheme();

  const autoplay = useRef(
    Autoplay({ delay: Math.floor(Math.random() * (12000 - 8000 + 1)) + 8000 })
  );

  const { data: { Recommended: reviewedModels = [] } = { Recommended: [], Hide: [] } } =
    trpc.user.getEngagedModels.useQuery(undefined, {
      enabled: !!currentUser,
      cacheTime: Infinity,
      staleTime: Infinity,
    });
  const hasReview = reviewedModels.includes(id);

  let contextMenuItems: React.ReactNode[] = [];
  contextMenuItems = contextMenuItems.concat([
    <AddToCollectionMenuItem
      key="add-to-collection"
      onClick={() =>
        openContext('addToCollection', { modelId: data.id, type: CollectionType.Model })
      }
    />,
  ]);

  contextMenuItems = contextMenuItems.concat([
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ShareButton
        url={router.asPath}
        title={data.name}
        collect={{ modelId: data.id, type: CollectionType.Model }}
      >
        <button
          className={classes.sharebtn}
          color={theme.colorScheme === 'dark' ? 'dark.6' : 'white'}
        >
          <div className={classes.icondiv}>
            <IconShare3 size={14} />
          </div>
          <div>Share</div>
        </button>
      </ShareButton>
    </div>,
  ]);

  contextMenuItems = contextMenuItems.concat([
    <ReportMenuItem
      key="report-model"
      label="Report"
      onReport={() =>
        openContext('report', {
          entityType: ReportEntity.Model,
          // Explicitly cast to number because we know it's not undefined
          entityId: data?.id as number,
        })
      }
    />,
  ]);

  const publishModelMutation = trpc.model.publish.useMutation({
    async onSuccess() {
      showSuccessNotification({
        title: 'published successfully',
        message: 'This model has been published',
      });
      setIsPublished(true);
    },
    onError(error) {
      setIsPublished(false);
      showErrorNotification({ error: new Error(error.message) });
    },
  });

  const unpublishModelMutation = trpc.model.unpublish.useMutation({
    onSuccess: async () => {
      showSuccessNotification({
        title: 'Unpublished successfully ',
        message: 'This model has been unpublished',
      });
      setIsPublished(false);
      // await queryUtils.model.getById.invalidate({ id: modelId });
      // await queryUtils.model.getAll.invalidate();
      // context.close();
    },
    onError: (error) => {
      setIsPublished(true);
      showErrorNotification({ error: new Error(error.message) });
    },
  });

  const isNew = data.createdAt && data.createdAt > threeDayAgo;

  let href = `/models/${data.id}/${slugit(data.name)}`;

  useEffect(() => {
    if (!modelId || modelId !== data.id) return;
    const elem = document.getElementById(`${modelId}`);
    if (elem) elem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
  }, [modelId, data.id]);

  useEffect(() => {
    setIsPublished(!(data?.meta && data?.meta?.unpublishedAt));
  }, [data?.meta && data?.meta?.unpublishedAt]);

  return (
    <HolidayFrame>
      <Indicator
        disabled={true}
        styles={{ indicator: { zIndex: 10, transform: 'translate(5px,-5px) !important' } }}
      >
        <MasonryCard ref={ref} withBorder shadow="sm" height={modelCardHeight} p={0}>
          {inView && (
            <>
              <LoadingOverlay visible={loading} zIndex={9} loaderProps={{ variant: 'dots' }} />
              {JSON.parse(data.image_urls ?? '[]').filter((url: string) => isValidUrl(url))
                .length === 1 ? (
                <div className={classes.imageContainer}>
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <NextLink
                      href={href}
                      className={`${classes.link} ${classes.root}`}
                      style={{ height: modelCardHeight }}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        if (!(e.ctrlKey || e.metaKey) && e.button !== 1) setLoading(true);
                      }}
                    >
                      <ModelEdgeMedia
                        src={
                          JSON.parse(data.image_urls).filter((url: string) => isValidUrl(url))[0]
                        }
                        alt={data.description}
                        width={cardWidth}
                        imageLoaded={(height: number) => setModelCardHeight(height)}
                        placeholder="empty"
                        className={classes.image}
                        style={{ borderRadius: 8 }}
                        wrapperProps={{ style: { zIndex: 1 } }}
                      />
                    </NextLink>
                  </Box>
                </div>
              ) : (
                <Carousel
                  key={carouselKey}
                  withControls
                  draggable
                  loop
                  plugins={[autoplay.current]}
                  onMouseEnter={autoplay.current.stop}
                  onMouseLeave={autoplay.current.reset}
                  style={{ flex: 1, height: 'calc(100% - 8px)' }}
                  withIndicators
                  controlSize={32}
                  getEmblaApi={setEmbla}
                  styles={{
                    viewport: {
                      height: '100%',
                    },
                    container: {
                      height: '100%',
                    },
                    indicators: {
                      bottom: -8,
                      zIndex: 5,
                      display: 'flex',
                      gap: 1,
                    },
                    indicator: {
                      width: 'auto',
                      height: 8,
                      flex: 1,
                      transition: 'width 250ms ease',
                      borderRadius: 0,
                      boxShadow: '0 0 3px rgba(0, 0, 0, .3)',
                    },
                  }}
                >
                  {JSON.parse(data.image_urls ?? '[]')
                    .filter((url: string) => isValidUrl(url))
                    .map((image_link: string, index: number) => (
                      <Carousel.Slide key={image_link} className={classes.slide}>
                        <div className={classes.imageContainer}>
                          <NextLink
                            href={href}
                            className={`${classes.link} ${classes.root}`}
                            style={{ height: modelCardHeight, width: '100%' }}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                              if (!(e.ctrlKey || e.metaKey) && e.button !== 1) setLoading(true);
                            }}
                          >
                            <ModelEdgeMedia
                              src={image_link}
                              alt={data.description}
                              width={cardWidth}
                              imageLoaded={(height: number) =>
                                index == 0 && setModelCardHeight(height)
                              }
                              placeholder="empty"
                              className={classes.image}
                              style={{ borderRadius: 8 }}
                              wrapperProps={{ style: { zIndex: 1 } }}
                              fadeIn
                            />
                          </NextLink>
                        </div>
                      </Carousel.Slide>
                    ))}
                </Carousel>
              )}
            </>
          )}
        </MasonryCard>
        {isNew && isPublished && (
          <EdgeMedia
            src="new_ribbon.png"
            style={{
              filter: 'unset',
              position: 'absolute',
              width: '135px',
              top: '-19px',
              left: '-19px',
            }}
          />
        )}

        {!isPublished && (
          <EdgeMedia
            src="unpublished.webp"
            style={{
              filter: 'unset',
              position: 'absolute',
              width: '135px',
              top: '-19px',
              left: '-19px',
            }}
          />
        )}

        <Box className={classes.topRight}>
          {contextMenuItems.length > 0 && (
            <Menu position="left-start" withArrow offset={-5}>
              <Menu.Target>
                <ActionIcon
                  variant="transparent"
                  p={0}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  sx={{
                    width: 30,
                    zIndex: 8,
                  }}
                >
                  <IconDotsVertical
                    size={24}
                    color="#fff"
                    style={{ filter: `drop-shadow(0 0 2px #000)` }}
                  />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {contextMenuItems.map((el, index) => (
                  <React.Fragment key={index}>{el}</React.Fragment>
                ))}
                {isModerator && isPublished && (
                  <Menu.Item
                    color="yellow"
                    icon={<IconAdjustmentsBolt size={14} stroke={1.5} />}
                    onClick={() => adjustReactionCount({ id: data.id, type: 'model' })}
                  >
                    Adjust reaction count
                  </Menu.Item>
                )}
                {isModerator && (
                  <Menu.Item
                    color="yellow"
                    icon={<IconCategory size={14} stroke={1.5} />}
                    onClick={() => openContext('categoryChangeModel', { model: data })}
                  >
                    Change category
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          )}
          <div style={{ display: 'grid', justifyItems: 'end' }}>
            <HoverActionButton
              label="Shop"
              style={{ marginTop: 8, marginBottom: 8 }}
              size={30}
              variant="filled"
              color={'gold'}
              data-activity="remix:model-gallery"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(data.deep_link);
              }}
            >
              <IconShoppingBag stroke={2.5} size={16} />
            </HoverActionButton>
            {isModerator && !isPublished && (
              <Button
                variant="light"
                color="green"
                onClick={() => publishModelMutation.mutate({ id: data.id })}
              >
                Publish
              </Button>
            )}
            {isModerator && isPublished && (
              <Button
                variant="light"
                color="yellow"
                onClick={() =>
                  unpublishModelMutation.mutate({
                    id: data.id,
                    reason: 'other',
                    customMessage: 'by moderator',
                  })
                }
              >
                Unpublish
              </Button>
            )}
          </div>
        </Box>
        <Reactions
          entityId={data?.id}
          entityType="model"
          reactions={data.reactions}
          metrics={{
            likeCount: data.rank?.likeCountAllTime,
            dislikeCount: data.rank?.dislikeCountAllTime,
            heartCount: data.rank?.heartCountAllTime,
            laughCount: data.rank?.laughCountAllTime,
            cryCount: data.rank?.cryCountAllTime,
          }}
          style={{
            bottom:
              JSON.parse(data.image_urls ?? '[]').filter((url: string) => isValidUrl(url))
                .length === 1
                ? 5
                : 14,
          }}
          className={classes.reactions}
        />
      </Indicator>
    </HolidayFrame>
  );
}

type Props = {
  index: number;
  // @ts-ignore
  data: UseQueryModelReturn[number];
  width: number;
};
